import React from 'react';
import { Col, Input, Tooltip } from 'antd';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import createUpdateOfferRules from '../../validation';
import { QuestionMark } from '../../../../../components/icons';
import { TooltipWrapper } from '../../styled';
import { FieldWithCurrencyWrapper } from '../../../../../components/form/styled';
import Form from '../../../../../components/form';
import { currencyBySupportedCountries } from '../../../../../utils/countries';
import { PublisherOfferFormModel } from '../../../types';

export interface MinSpendProps {
  disabled: boolean;
}

export default function MinSpend({ disabled }: MinSpendProps) {
  const { t } = useTranslation('offers');

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<PublisherOfferFormModel>();

  const { discountType, discountAmount, country } = watch();
  const isDiscountExact = discountType === 'exact';

  const shouldHaveMinSpend = false;

  const rules = {
    ...createUpdateOfferRules.minSpend,
    required: shouldHaveMinSpend,
    min: isDiscountExact && discountAmount ? parseFloat(discountAmount) : 0,
  };

  return (
    <Col span={12} key="minSpend-field">
      <Form.Item>
        <FieldWithCurrencyWrapper>
          <Form.ItemController
            label={
              <>
                {t(`fields.minSpend.label`)}

                <TooltipWrapper>
                  <Tooltip
                    title={t(`fields.minSpend.tooltip`)}
                    placement="bottomRight"
                  >
                    <QuestionMark />
                  </Tooltip>
                </TooltipWrapper>
              </>
            }
            errors={errors}
            data-testid="minSpend"
            controller={{
              name: 'minSpend',
              control,
              disabled,
              render: ({ field }) => (
                <Input
                  {...field}
                  type="number"
                  prefix={currencyBySupportedCountries[country] || '--'}
                  placeholder={
                    shouldHaveMinSpend
                      ? t('fields.minSpend.label')
                      : t('createUpdate.optional')
                  }
                  min={+(createUpdateOfferRules.minSpend.min ?? 0)}
                  disabled={disabled}
                />
              ),
              rules,
            }}
          />
        </FieldWithCurrencyWrapper>
      </Form.Item>
    </Col>
  );
}
