import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Row, Checkbox, Col, Tooltip, InputNumber } from 'antd';
import { Offer } from '@fidel.uk/types';
import Form from '../../../../../components/form';
import { CreateUpdateOfferFormModel } from '../../../types';
import { colors } from '../../../../../theme';
import { QuestionMark } from '../../../../../components/icons';
import { TooltipWrapper } from '../../styled';
import { Select } from '../../../../../components/select';

const offerMaxRedemptionsUnitValues = Object.values(
  Offer.OfferMaxRedemptionsUnit,
);

export default function MaxRedemptionsInput({
  isUpdating,
}: {
  isUpdating: boolean;
}) {
  const { t } = useTranslation('offers');

  const { watch } = useFormContext<CreateUpdateOfferFormModel>();

  const {
    control,
    trigger,
    resetField,
    formState: { errors },
  } = useFormContext<CreateUpdateOfferFormModel>();

  const hasMaxRedemptions = watch('maxRedemptions');

  const period = watch('period');
  const unit = watch('unit');

  const validatePeriodField = useCallback(
    periodValue => {
      if (periodValue) {
        trigger('unit');
      } else {
        resetField('period');
      }
    },
    [resetField, trigger],
  );

  const validateUnitField = useCallback(
    unitValue => {
      if (unitValue) {
        trigger('period');
      } else {
        resetField('unit');
      }
    },
    [resetField, trigger],
  );

  useEffect(() => {
    validatePeriodField(period);
    validateUnitField(unit);
  }, [period, unit, validatePeriodField, validateUnitField]);

  return (
    <>
      <Form.ItemController
        errors={errors}
        controller={{
          name: 'maxRedemptions',
          disabled: isUpdating,
          render: ({ field: { value, onChange, ...rest } }) => (
            <Row>
              <Col>
                <Checkbox
                  {...rest}
                  onChange={({ target }) => onChange(target.checked)}
                  checked={value}
                  data-testid="maxRedemptions"
                  disabled={isUpdating}
                >
                  <Trans
                    i18nKey="createUpdate.maxRedemptions.title"
                    ns="offers"
                  />
                </Checkbox>
              </Col>

              <Col>
                <TooltipWrapper>
                  <Tooltip
                    title={t('createUpdate.maxRedemptions.tooltip')}
                    placement="bottomRight"
                  >
                    <QuestionMark />
                  </Tooltip>
                </TooltipWrapper>
              </Col>
            </Row>
          ),
          control,
        }}
      />
      {hasMaxRedemptions ? (
        <Row style={{ display: 'flex', marginTop: '0.5rem' }}>
          <div style={{ width: '100%' }}>
            <Form.ItemController
              label={
                <span
                  style={{
                    fontSize: '14px',
                    letterSpacing: '0.3px',
                    color: colors.black,
                  }}
                >
                  {t('createUpdate.maxRedemptions.transactionsLimit.label')}
                </span>
              }
              errors={errors}
              controller={{
                name: 'transactionsLimit',
                disabled: isUpdating,
                render: ({ field }) => (
                  <InputNumber
                    {...field}
                    min={1}
                    data-testid="transactionsLimit"
                    placeholder={t('createUpdate.enterValue')}
                    disabled={isUpdating}
                  />
                ),
                rules: {
                  min: 1,
                  required: true,
                },
                control,
              }}
            />
          </div>
          <div style={{ width: '100%', display: 'flex', gap: '1rem' }}>
            <Form.ItemController
              label={
                <span
                  style={{
                    fontSize: '14px',
                    letterSpacing: '0.3px',
                    color: colors.black,
                  }}
                >
                  {t('createUpdate.maxRedemptions.period.label')}
                </span>
              }
              errors={errors}
              controller={{
                name: 'period',
                render: ({ field }) => (
                  <InputNumber
                    {...field}
                    min={1}
                    data-testid="period"
                    placeholder={t('createUpdate.enterValue')}
                    disabled={isUpdating}
                  />
                ),
                disabled: isUpdating,
                rules: {
                  validate: {
                    min: value => (unit ? value >= 1 : true),
                  },
                  required: !!unit,
                },
                control,
              }}
            />
            <Form.ItemController
              label={t('createUpdate.maxRedemptions.unit.label')}
              style={{ width: '100%' }}
              errors={errors}
              data-testid="unit"
              controller={{
                name: 'unit',
                render: ({ field }) => (
                  <Select
                    {...field}
                    style={{ width: '100%' }}
                    disabled={isUpdating}
                    allowClear
                  >
                    {offerMaxRedemptionsUnitValues.map(value => (
                      <Select.Option key={value} value={value}>
                        {t(`createUpdate.maxRedemptions.unit.${value}`)}
                      </Select.Option>
                    ))}
                  </Select>
                ),
                disabled: isUpdating,
                rules: {
                  required: !!period,
                },
                control,
              }}
            />
          </div>
        </Row>
      ) : (
        <>{/* we need an empty element that is not null, to satisfy antd */}</>
      )}
    </>
  );
}
