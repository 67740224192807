/* Antd's form does not have an onSubmit handler which we need for React Hook Form
 This component provides the styling/layout classes for Antd but on a normal form element */

import React, { HTMLAttributes, ReactElement } from 'react';
import { Form as AntdForm } from 'antd';
import {
  Controller as RHFController,
  FieldError,
  DeepMap,
  Control,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { get } from 'lodash';
import regex from '../../utils/regex';
import showConditionalError, {
  ErrorMessage,
} from '../../utils/show-conditional-error';
import { fonts } from '../../theme';

interface FormProps extends HTMLAttributes<HTMLFormElement> {
  layout?: 'inline' | 'vertical' | 'horizontal';
}

interface FormComponentType extends FormProps {
  Item?: typeof AntdForm.Item;
  ItemController?: typeof FormItemController;
}

const Form = ({ layout = 'horizontal', ...props }: FormComponentType) => (
  <form {...props} className={`ant-form ant-form-${layout}`} />
);

export type ControllerType = Omit<
  React.ComponentProps<typeof RHFController>,
  'control'
> & {
  control: Control<any>;
  disabled?: boolean;
};

interface FormItemControllerProps
  extends React.ComponentProps<typeof AntdForm.Item> {
  controller: ControllerType;
  errors?: DeepMap<any, FieldError>;
  validateCharacters?: boolean;
  validateEmail?: boolean;
  customHelp?: ErrorMessage[];
  itemDescription?: ReactElement;
}

const FormItemController = ({
  children,
  controller,
  errors,
  validateStatus,
  validateCharacters,
  validateEmail,
  customHelp = [],
  itemDescription,
  ...rest
}: FormItemControllerProps) => {
  const { t } = useTranslation();

  const { rules, name, disabled } = controller;

  // names can be lookup paths with period seperators, see select-transactions/schemes
  const errorField = get(errors || {}, name);

  // workaround for react-hook-form not properly handling validation on disabled fields
  const controllerRules = disabled
    ? {}
    : {
        ...rules,
        validate: {
          ...rules?.validate,
          forbiddenCharacters: (value: string) =>
            validateCharacters ? regex.forbiddenCharacters.test(value) : true,
        },
      };

  return (
    <>
      <AntdForm.Item
        {...rest}
        colon={false}
        labelAlign="left"
        validateStatus={(errorField && 'error') || validateStatus}
        help={showConditionalError(
          errorField as FieldError,
          [
            ...customHelp,
            {
              type: ['required', 'minLength', 'maxLength', 'password'],
              message: t('signUp.error.password'),
            },
            {
              type: ['minLength', 'maxLength'],
              message: t('form.validation.length', {
                min: rules?.minLength,
                max: rules?.maxLength,
              }),
            },
            {
              type: ['min', 'max'],
              message: t('form.validation.minMax', {
                min: rules?.min,
                max: rules?.max,
              }),
            },
            {
              type: ['min'],
              message: t('form.validation.min', {
                min: rules?.min,
              }),
            },
            {
              type: 'maxLength',
              message: t('form.validation.maxLength', {
                max: rules?.maxLength,
              }),
            },
            {
              type: 'minLength',
              message: t('form.validation.minLength', {
                min: rules?.minLength,
              }),
            },
            {
              type: 'invalidUrl',
              message: t('form.validation.url'),
            },
            {
              type: 'invalidImgUrl',
              message: t('form.validation.imgUrl'),
            },
            {
              type: 'numeric',
              message: t('form.validation.numeric'),
            },
            {
              type: 'numericWith2DecPlaces',
              message: t('form.validation.numericWith2DecPlaces'),
            },
            {
              type: 'alphanumeric',
              message: t('form.validation.alphanumeric'),
            },
            {
              type: 'alphanumericDashes',
              message: t('form.validation.alphanumericDashes'),
            },
            {
              type: 'alphanumericSpaces',
              message: t('form.validation.alphanumericSpaces'),
            },
            {
              type: 'invalidKey',
              message: t('form.validation.invalidKey'),
            },
            {
              type: 'blacklistedHeader',
              message: t('form.validation.blacklistedHeader'),
            },
            {
              type: ['required', 'email'],
              message: t('form.validation.email'),
            },
            {
              type: ['required', 'terms'],
              message: t('form.validation.terms'),
            },
            {
              type: 'required',
              message: t('form.validation.required'),
            },
            {
              type: 'vatNumber',
              message: t('form.validation.vatNumber'),
            },
            {
              type: 'cityName',
              message: t('form.validation.cityName'),
            },
            {
              type: 'maxInvoiceEmails',
              message: t('form.validation.maxInvoiceEmails'),
            },
            {
              type: 'duplicateMids',
              message: t('form.validation.duplicateMids'),
            },
            {
              type: 'exactId',
              message: t('form.validation.exactId'),
            },
            {
              type: 'feesCustomisationSurpass',
              message: t('form.validation.feesCustomisationSurpass'),
            },
            {
              type: 'forbiddenCharacters',
              message: (
                <>
                  {t('form.validation.forbiddenCharacters')}{' '}
                  <span
                    style={{ fontFamily: fonts.monospace }}
                  >{`/\\|[](){}<>`}</span>
                </>
              ),
            },
          ],
          controllerRules,
        )}
      >
        <RHFController {...controller} rules={controllerRules} />
        {children}
      </AntdForm.Item>
      {itemDescription}
    </>
  );
};

Form.Item = AntdForm.Item;
Form.ItemController = FormItemController;

export default Form;
